import React, { useEffect } from 'react';
import type { ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { install } from 'resize-observer';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import '../vendor/perfect-scrollbar.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';

import '../i18n';
import createTheme from '../theme';
import { GA_TRACKING_ID, PageView } from '../utils/gtag';

import { ThemeProvider } from '../contexts/ThemeContext';
import useTheme from '../hooks/useTheme';
import createEmotionCache from '../utils/createEmotionCache';

const clientSideEmotionCache = createEmotionCache();

type GetLayout = (page: ReactNode) => ReactNode;

type Page<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
    getLayout?: GetLayout;
};

type MyAppProps<P = Record<string, never>> = AppProps<P> & {
    emotionCache?: EmotionCache;
    Component: Page<P>;
};

if (typeof window !== 'undefined') {
    install();
}

function App({ Component, emotionCache = clientSideEmotionCache, pageProps }: MyAppProps) {
    const { theme } = useTheme();

    const getLayout = Component.getLayout ?? ((page: ReactNode) => page);
    const router = useRouter();
    useEffect(() => {
        // GA_TRACKING_ID が設定されていない場合は、処理終了
        if (!GA_TRACKING_ID) return;

        const handleRouteChange = (url: string) => {
            PageView(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <CacheProvider value={emotionCache}>
            <HelmetProvider>
                <Helmet titleTemplate="%s | Green Digital Track Bond" defaultTitle="Green Digital Track Bond" />
                <MuiThemeProvider theme={createTheme(theme)}>
                    {getLayout(<Component {...pageProps} />)}
                </MuiThemeProvider>
            </HelmetProvider>
        </CacheProvider>
    );
}

const withThemeProvider = (Component: any) => {
    const AppWithThemeProvider = (props: JSX.IntrinsicAttributes) => {
        return (
            <ThemeProvider>
                <Component {...props} />
            </ThemeProvider>
        );
    };
    AppWithThemeProvider.displayName = 'AppWithThemeProvider';
    return AppWithThemeProvider;
};

export default withThemeProvider(App);
